import { useState, useEffect } from 'react'
import axios from 'axios'
import { styled } from 'styled-components'
import Spin from '../../components/SpinV4'
import './index.css'
import spin1 from '../../assets/svg/spin1.svg'
import spin2 from '../../assets/svg/spin2.svg'
import barca from '../../assets/icon/barca.png'
import real from '../../assets/icon/real.png'
import bayern from '../../assets/icon/bayern.png'
import liverpool from '../../assets/icon/liverpool.png'
import chelsea from '../../assets/icon/chelsea.png'
import city from '../../assets/icon/city.png'
import Timer from '../../components/Timer'
import {config} from '../../utilis/Config'
import CountDown from '../../components/CountDown'
import { TimerContext } from '../../utilis/Context'

const Number = styled.span`
    color: ${(props) => (props.bg === '#fff' ? '#000' : '#fff')};
    background-color: ${(props) => props.bg};
    background-opacity: ${(props) =>
        props.active === props.value ? '1' : '0.5'};

    &:after {
        content: '✅';
        display: ${(props) =>
            props.active === props.value ? 'inline' : 'none'};
        position: absolute;
        // margin-left: -10px;
        // padding-top: 18px;
        // top: 0;
    }
`

const Logo = styled.span`
    background-image: ${bayern};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`

// const Number = styled.span`
//       color : ${(props) => props.active === props.value ? '#fff' : '#000'}
// `

export default function Home() {
    const datas = [
        {
            value: 'LIVERPOOL',
            interval: [1, 170],
            point: 2.5,
            occurence: 6,
            bg: '#EB010E',
            logo: liverpool,
            // priority : 1,
        },
        {
            value: 'REAL',
            interval: [181, 261],
            point: 5,
            occurence: 6,
            bg: '#fff',
            logo: real,
            // priority : 1,
        },
        {
            value: 'BARÇA',
            interval: [270, 352],
            point: 5,
            occurence: 6,
            bg: '#B10020',
            logo: barca,
            // priority : 1,
        },
        {
            value: 'H',
            interval: [171, 180],
            point: 7,
            occurence: 3,
            bg: '#0C351A',
            // priority : 1,
        },
        {
            value: 'F',
            interval: [262, 269],
            point: 7,
            occurence: 3,
            bg: '#27A685',
            // priority : 1,
        },
        {
            value: 'E',
            interval: [353, 359],
            point: 7,
            occurence: 3,
            bg: '#650072',
            // priority : 1,
        },
    ]
    const datas1 = [
        {
            value: 'CITY',
            interval: [1, 170],
            point: 2,
            occurence: 6,
            bg: '#4B7EBE',
            logo: city,
            // priority : 1,
        },
        {
            value: 'BAYERN',
            interval: [180, 261],
            point: 4,
            occurence: 6,
            bg: '#B10020',
            logo: bayern,
            // priority : 1,
        },
        {
            value: 'CHELSEA',
            interval: [270, 352],
            point: 4,
            occurence: 6,
            bg: '#0C0261',
            logo: chelsea,
            // priority : 1,
        },
        {
            value: 'black',
            interval: [171, 179],
            point: 10,
            occurence: 3,
            bg: '#000',
            // priority : 1,
        },
        {
            value: 'white',
            interval: [262, 269],
            point: 10,
            occurence: 3,
            bg: '#fff',
            // priority : 1,
        },
        {
            value: 'red',
            interval: [353, 359],
            point: 10,
            occurence: 3,
            bg: '#B10020',
            // priority : 1,
        },
    ]

    //react countdown from 5 to 1 that fires after every 20s?

    // const datas = [
    //     {
    //         value: 1,
    //         position: 0 || 360,
    //     },
    //     {
    //         value: 2,
    //         position: 40,
    //     },
    //     {
    //         value: 3,
    //         position: 80,
    //     },
    //     {
    //         value: 4,
    //         position: 120,
    //     },
    //     {
    //         value: 5,
    //         position: 160,
    //     },
    //     {
    //         value: 6,
    //         position: 200,
    //     },
    //     {
    //         value: 7,
    //         position: 240,
    //     },
    //     {
    //         value: 8,
    //         position: 280,
    //     },
    //     {
    //         value: 9,
    //         position: 320,
    //     },
    // ]

    const [activeNumber1, setActiveNumber1] = useState(null)
    const [activeNumber2, setActiveNumber2] = useState(null)
    const [spinPosition1, setSpinPosition1] = useState(null)
    const [spinPosition2, setSpinPosition2] = useState(null)

    const [count, setCount] = useState(4)
    const [isCounting, setIsCounting] = useState(false)

    return (
        <TimerContext.Consumer>
            {({runSpin}) => (
                <div className="h-screen w-screen bg-blue-600 flex flex-col justify-center items-center p-5">
                {isCounting && (
                    <CountDown isCounting={isCounting} count={count} setCount={setCount}/>
                )}
                <div className="    ">
                    <Timer/>
                </div>
                <div className="h-full w-full flex justify-around flex-row items-center">
                    <div className="flex flex-col gap-8 justify-around items-center h-100 lg:h-125">
                        <Spin
                            svg={spin1}
                            datas={datas1}
                            spinPosition={spinPosition1}
                            setSpinPosition={setSpinPosition1}
                            setActiveValue={setActiveNumber1}
                            setIsCounting={setIsCounting}
                            setCount={setCount}
                            isCounting={isCounting}
                            joker="left"
                            runSpin={runSpin}
                        />
                        <div className=" p-2 parent">
                            {datas1.map((data, index) => (
                                <div
                                    key={index + data.value + 1}
                                    className="flex flex-row items-center rounded-l-full"
                                >
                                    <img
                                        className="h-8 w-"
                                        key={index + data.logo}
                                        src={data.logo}
                                        alt=""
                                        srcSet=""
                                    />
                                    {/* <Logo bg={data.logo} className={"p-4 h-2 w-4 bg-white rounded-full bg-[url('"+ data.logo +"')]"}></Logo> */}
                                    <Number
                                        className={
                                            ' div text-left p-1 font-semibold'
                                        }
                                        value={data.value}
                                        active={activeNumber1}
                                        bg={data.bg}
                                        logo={data.logo}
                                    >
                                        {data.occurence !== 1 && data.value} x
                                        {data.point}
                                    </Number>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="hidden md:flex flex-col gap-8 justify-around items-center h-100 lg:h-125">
                        <Spin
                            svg={spin2}
                            datas={datas}
                            spinPosition={spinPosition2}
                            setSpinPosition={setSpinPosition2}
                            setActiveValue={setActiveNumber2}
                            setIsCounting={setIsCounting}
                            setCount={setCount}
                            isCounting={isCounting}
                            joker="right"
                            runSpin={runSpin}
                        />
                        <div className=" p-2 parent">
                            {datas.map((data, index) => (
                                <div
                                    key={index + data.value + 1}
                                    className="flex flex-row items-center rounded-l-full"
                                >
                                    <img
                                        className="h-8 w-"
                                        key={index + data.logo}
                                        src={data.logo}
                                        alt=""
                                        srcSet=""
                                    />
                                    {/* <Logo bg={data.logo} className="p-4 h-2 w-4 bg-white rounded-full"></Logo> */}
                                    <Number
                                        key={index + data.value + 1}
                                        className={
                                            'div text-left p-1 font-semibold'
                                        }
                                        value={data.value}
                                        active={activeNumber2}
                                        bg={data.bg}
                                        logo={data.logo}
                                    >
                                        {data.occurence !== 1 && data.value} x
                                        {data.point}
                                    </Number>
                                    {/*  */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            )}
        </TimerContext.Consumer>
    )
}
