import { useRef, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import './index.css'
import Chance from 'chance'
import arrow from '../../assets/svg/arrow.svg'
// import axios from 'axios'
// import { config } from '../../utilis/Config'
// import Timer from '../../utilis/Timer'

export default function SpinV4({
    datas,
    spinPosition,
    setSpinPosition,
    setActiveValue,
    joker,
    setIsCounting,
    setCount,
    isCounting,
    svg,
    runSpin
}) {
    // //console.log(SpinDiv.attrs)
    const [longTime, setLongTime] = useState(false)
    const [spinArray, setSpinArray] = useState(null)
    const [spinArrayIndex, setSpinArrayIndex] = useState(null)
    const [spinArrayCurrentValue, setSpinArrayCurrentValue] = useState(null)

    const spinContainerRef = useRef()
    const chance = new Chance()
    // const theSpin = spinContainerRef.current
    const spinData = datas
    const mouv = 8640
    // let number = mouv - gessNextMouv()

    // const timer = new Timer()
    // console.log('spin-render')

    //using react js, an app that on load fires a function that displays a random number every 30seconds and stores it in a state?

    function spin(from, to) {
        const spinNextMouv = (from - mouv - to) + Math.abs(spinPosition) % 360

        if (
            Math.abs(spinNextMouv) % 360 === 0 ||
            Math.abs(spinNextMouv) % 360 === 360
        ) {
            setSpinPosition(spinNextMouv)
            // setSpinPosition(spinNextMouv - 5)
        } else {
            // setlastPosition(spinPosition)
            setSpinPosition(spinNextMouv)
        }
    }

    // function autoSpin(from, to) {
    //     const spinNextMouv = from + mouv + to

    //     if (
    //         Math.abs(spinNextMouv) % 360 === 0 ||
    //         Math.abs(spinNextMouv) % 360 === 360
    //     ) {
    //         setSpinPosition(spinNextMouv - 5)
    //     } else {
    //         setSpinPosition(spinNextMouv)
    //     }
    // }

    const handleSpin = () => {
        //start here
        setSpinArrayCurrentValue(spinArray[spinArrayIndex])
        setSpinArrayIndex((prev) => prev + 1)
        // console.log(generateRandomArray(datas))
        let nextMouv = gessNextMouv(spinArrayCurrentValue)
        spin(spinPosition !== null ? spinPosition : 0, nextMouv)
    }
    function handleChronoSpin() {
        setInterval(() => {
            handleSpin()
        }, 5000)
    }

    // function getPosition(spinContainer) {
    //     //console.log()
    //     // let v1 =
    // }

    function getValue(position, datass) {
        let x = Math.abs(position) % 360
        //console.log(position)
        //console.log(x)

        return (
            datass &&
            datass.find((data) => {
                return data.interval[0] <= x && x <= data.interval[1]
            }).value
        )
    }

    function generateArray(data) {
        const dataHolder = []
        const dataLength = data.dataLength
        // for (let index = 0; index < array.length; index++) {
        //     const element = array[index];

        // }
    }

    function generateRandomArray(array) {
        //reverse array and change data indexes randomly?
        // console.log(array)
        const myArray = mixArray(array)
        // console.log(mixArray(array))
        // const myArray = array
        // console.log(mixArray(array))
        const randomArray = []
        // //console.log(randomIndex)
        // let old = 0
        do {
            // for (let i = 0; i < 15; i++) {
            const randomIndex = Math.floor(Math.random() * myArray.length)
            let indexedData = myArray[randomIndex]
            if (randomArray.length > 0) {
                //search if already in the array
                if (
                    randomArray.find((el) => {
                        return el.point === indexedData.point
                    })
                ) {
                    //itexiste
                    if (
                        randomArray.filter((el) => {
                            return el.point === indexedData.point
                        }).length < indexedData.occurence
                    ) {
                        randomArray.push(myArray[randomIndex])
                    }
                } else {
                    //itnoexists
                    randomArray.push(myArray[randomIndex])
                }
            } else {
                randomArray.push(myArray[randomIndex])
            }

            // }
        } while (randomArray.length < 15)

        // Return the new array
        return randomArray
    }

    function mixArray(array) {
        // Reverse the array
        let reversedArray = array

        // Create a new array to store the randomly shuffled data?
        let shuffledArray = []
        // console.log(reversedArray)

        // Iterate over the reversed array
        for (let i = 0; i < reversedArray.length; i++) {
            // Generate a random index
            const randomIndex = Math.floor(Math.random() * reversedArray.length)

            // Swap the elements at the current index and the random index
            ;[reversedArray[i], reversedArray[randomIndex]] = [
                reversedArray[randomIndex],
                reversedArray[i],
            ]
        }

        // Return the shuffled array
        return reversedArray
    }

    // start()

    function gessNextMouv(array) {
        let next
        // do {
        // next = Math.ceil(Math.random() * 12000)

        // } while (next % 40 !== 0)
        // console.log(array)
        next = array ? chance.integer({ min: array.interval[0], max: array.interval[1] }) : chance.integer({ min: 0, max: 360 })
        // console.log(next)
        return next
    }

    // const timeout = setTimeout(() => {
    //         handleSpin()
    //         setIsCounting(true)
    //         setCount(4)
    //         console.log('here')
    //         setLongTime(true)
    // }, 1000)

    // clearTimeout(timeout)

    // jokers

    //game time down
    useEffect(() => {
        // const interval = setTimeout(() => {
            // handleSpin()
            runSpin && setIsCounting(true)
            runSpin && setCount(4)
        // }, 30000)

        // return () => clearTimeout(interval)
    }, [runSpin])

    //count down
    useEffect(() => {
        isCounting && setTimeout(() => {
            handleSpin() 
               
            // console.log(spinArrayCurrentValue)
            setIsCounting(false)
            // setCount(5)
        }, 5000)
    },[isCounting])

    ///spin tour time
    useEffect(() => {
        if(spinArray === null || spinArrayIndex === null || spinArrayIndex === 16){
            const jokerArr = generateRandomArray(datas)
            // console.log(jokerArr)
            setSpinArrayIndex(1)
            setSpinArrayCurrentValue(jokerArr[0])
            setSpinArray(jokerArr)
        }

        spinPosition &&
            setTimeout(() => {
                setActiveValue(getValue(spinPosition, spinData))
            }, 15000)
    }, [spinPosition])

    return (
        <div className="big-container">
            {/* <span id="arrow" className="arrow mb-75 lg:mb-100 "> */}
                <img id='arrow' className='arrow mb-75 lg:mb-100' src={arrow} alt='arrow'/>
            {/* </span> */}
            {/* <button
                className="top-0 z-10 aboslute mr-100"
                id=""
                onClick={handleChronoSpin}
            >
                Spinthis
            </button> */}
            {/* <button className="spin text-6" id="spin" onClick={handleSpin}>
                ⚽️
            </button> */}
            <SpinDiv
                id="the-spin"
                className="spin-container h-100 w-100 lg:w-125 lg:h-125"
                rotate={spinPosition}
                ref={spinContainerRef}
                bg={svg}
            >
                {/* <img src={svg} alt='svgg'/> */}
                {/* {svg} */}
            </SpinDiv>
        </div>
    )
}

const SpinDiv = styled.div`
    // width: 300px;
    // height: 300px;
    background-image: url('${(props) => props.bg}');
    background-size: contain;
    background-repeat: no-repeat;
    // background-color: #ccc;
    // border-radius: 50%;
    // border: 15px solid #ffd700;
    position: fixed;
    overflow: hidden;
    transition: 15s;
    transform: rotate(${(props) => props.rotate}deg);
`
