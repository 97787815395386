import {useEffect } from 'react'

export default function CountDown({isCounting, count, setCount}) {

    // console.log('countdown-render')

    useEffect(() => {

        const interval = setInterval(() => {
            if (isCounting) {
                setCount((prevCount) =>
                    prevCount === 'GO'
                        ? 4
                        : prevCount > 1
                        ? prevCount - 1
                        : 'GO',
                )
            }
        }, 1000)

        return () => clearInterval(interval)
    })

    return (
        <div className="absolute w-screen h-screen flex justify-center items-center z-110 bg-black bg-opacity-50">
            <span className="text-40 text-white font-semibold">{count}</span>
        </div>
    )
}
