import { TimerContext } from '../../utilis/Context'
export default function Timer() {

    return (
        <TimerContext.Consumer>
            {({timer}) => (
                <span className="flex justify-center gap-2 text-white text-10">
                    ⏳00:{timer}
                </span>
            )}
        </TimerContext.Consumer>
    )
}
